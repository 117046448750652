import React, { useState, useContext, useEffect } from 'react';
import { Col, Row, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Section from '../section/section.js';
import ChartCard from '../chart-card/chart-card';
import EstablishmentTypeToggle from '../establishment-type-toggle/establishment-type-toggle';
import Context from '../../context/context';
import TreeMapChart from '../tree-map-chart/tree-map-chart.js';

import './business-type-distribution.css';

const BusinessTypeDistribution = () => {
  const {
    filteredEstablishments,
    selectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
  } = useContext(Context);

  const [establismentDistributionData, setEstablismentDistributionData] =
    useState(computeEstablismentDistributionData());
  const [topTenEstablishments, setTopTenEstablishments] = useState(
    computeTopTenEstablishments()
  );

  function computeEstablismentDistributionData() {
    const data = filteredEstablishments.reduce(
      (establishmentAcc, establishment) => {
        if (!establishment.subcategory) {
          establishmentAcc['Unknown'] += 1;
          return establishmentAcc;
        }
        establishment.subcategory.split(',').forEach((subcategory) => {
          if (!establishmentAcc[subcategory]) {
            establishmentAcc[subcategory] = 1;
          } else {
            establishmentAcc[subcategory] += 1;
          }
        });
        return establishmentAcc;
      },
      { Unknown: 0 }
    );

    if (data.Unknown === 0) {
      delete data.Unknown;
    }
    return data;
  }

  function computeTopTenEstablishments() {
    return filteredEstablishments
      .sort((a, b) => b.ranking_score - a.ranking_score) // Sort establishments in descending order by ranking_score
      .slice(0, 10);
  }

  useEffect(() => {
    setEstablismentDistributionData(computeEstablismentDistributionData());
    setTopTenEstablishments(computeTopTenEstablishments());
  }, [filteredEstablishments]);

  const appliedFilters = {
    platform: selectedPlatform,
    category: selectedCategory,
    subcategories: selectedSubCategory,
    sentiment: selectedSentiment,
    aspects: selectedAspect,
    regions: selectedRegion,
  };

  return (
    <Section title={<span>Distribution of Types</span>}>
      <Row gutter={[24, 24]}>
        <Col span={14}>
          <EstablishmentTypeToggle />
          <Section title={<span>{selectedCategory} Types</span>}>
            <TreeMapChart
              id="categories-treemap"
              height={400}
              hasLegend={true}
              data={establismentDistributionData}
              filters={appliedFilters}
              title="Distribution of Types"
            />
          </Section>
        </Col>
        <Col span={10}>
          <ChartCard>
            <h2 className="title-small">{selectedCategory}</h2>
            <h3 className="title-orange">Top 10</h3>
            <Space style={{ width: '100%' }} direction="vertical" size={8}>
              {topTenEstablishments.map((establishment, index) => (
                <div key={establishment.url} className="top-list-item">
                  <span className="top-list-item-rating">{index + 1} </span>
                  <a
                    href={establishment.url}
                    target="_blank"
                    className="top-list-item-link"
                    key={index}
                  >
                    <span>{establishment.name}</span>
                    <ArrowRightOutlined className="top-list-item-arrow" />
                  </a>
                </div>
              ))}
            </Space>
          </ChartCard>
        </Col>
      </Row>
    </Section>
  );
};

BusinessTypeDistribution.propTypes = {};

BusinessTypeDistribution.defaultProps = {};

export default BusinessTypeDistribution;
