import React, { useEffect, useRef, useState } from 'react';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';
import * as d3 from 'd3';
import './bar-chart.css';

const HorizontalBarChart = ({ data, color }) => {
  const chartRef = useRef(null);
  const xAxisRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const margin = { top: 12, right: 20, bottom: 30, left: 120 };

  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTop, setTooltipTop] = useState('0px');
  const [tooltipLeft, setTooltipLeft] = useState('0px');

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Clear previous charts
    d3.select(chartRef.current).selectAll("*").remove();
    d3.select(xAxisRef.current).selectAll("*").remove();

    const container = scrollContainerRef.current;
    if (container) {
      container.scrollTop = 0;
    }

    const containerWidth = chartRef.current.getBoundingClientRect().width;
    const chartWidth = containerWidth - margin.left - margin.right;
    const chartHeight = data.length * 25 - margin.top;
    const xAxisHeight = margin.bottom;

    // Create main chart SVG
    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', containerWidth)
      .attr('height', chartHeight)
      .append('g')
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Create separate SVG for x-axis
    const xAxisSvg = d3.select(xAxisRef.current)
      .append('svg')
      .attr('width', containerWidth)
      .attr('height', xAxisHeight)
      .append('g')
      .attr("transform", `translate(${margin.left}, 0)`);

    const y = d3.scaleBand()
      .range([0, chartHeight - margin.top])
      .domain(data.map(d => d.key))
      .padding(0.2);

    const x = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([0, chartWidth - 20]);

    // Add y-axis to main chart
    svg.append("g")
      .attr('class', 'y-axis')
      .call(d3.axisLeft(y));

    // Add x-axis to separate SVG
    xAxisSvg.append("g")
      .attr('class', 'x-axis')
      .call(d3.axisBottom(x).ticks(5));

    // Remove unnecessary lines
    svg.selectAll('.y-axis path, .y-axis line').remove();
    xAxisSvg.selectAll('.x-axis path, .x-axis line').remove();
    svg.selectAll(".tick text").attr('class', 'chart_tick_text');
    xAxisSvg.selectAll(".tick text").attr('class', 'chart_tick_text');

    // Add bars
    svg.selectAll(".mybar")
      .data(data)
      .enter()
      .append("rect")
      .attr('class', 'mybar')
      .attr("fill", color || "#FDB022")
      .attr('rx', d => y.bandwidth() > 16 ? 8 : y.bandwidth() / 2)
      .attr("y", d => y(d.key))
      .attr("x", 0)
      .attr("width", d => x(d.value))
      .attr("height", d => Math.min(y.bandwidth(), 16))
      .on('mouseover', function(event, d) {
        svg.selectAll('rect').attr('fill-opacity', 0.6);
        d3.select(this).attr('fill-opacity', 1);

        svg.selectAll('.y-axis text').attr('fill-opacity', 0.6);
        svg.selectAll('.y-axis text')
          .filter((_, i) => data[i].key === d.key)
          .attr('fill-opacity', 1);

        setTooltipTitle(d.key);
        setTooltipValue(d.value);
        setTooltipVisible(true);
      })
      .on('mousemove', function(event) {
        const chartRect = chartRef.current.getBoundingClientRect();
        const containerRect = scrollContainerRef.current.getBoundingClientRect();
        
        // Calculate position relative to the visible container
        const mouseX = event.clientX - chartRect.left;
        const mouseY = event.clientY - containerRect.top;
        
        setTooltipTop(`${mouseY}px`);
        setTooltipLeft(`${mouseX}px`);
      })
      .on('mouseout', function() {
        svg.selectAll('rect').attr('fill-opacity', 1);
        svg.selectAll('.y-axis text').attr('fill-opacity', 1);
        setTooltipVisible(false);
      });

  }, [data, color]);

  return (
    <div className="chart-container" style={{ position: 'relative', maxWidth: '100%' }}>
      <div style={{ 
        position: 'relative', 
        height: '400px',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div 
          ref={scrollContainerRef}
          style={{ 
            flexGrow: 1, 
            overflowY: 'auto', 
            overflowX: 'hidden',
            position: 'relative'  // Added to establish positioning context
          }}
        >
          <div ref={chartRef}></div>
        </div>
        <div ref={xAxisRef} style={{ 
          flexShrink: 0, 
          height: `${margin.bottom}px`,
          // backgroundColor: 'white'
        }}></div>
      </div>
      <ChartTooltip
        top={tooltipTop}
        left={tooltipLeft}
        title={tooltipTitle}
        value={tooltipValue}
        visible={tooltipVisible}
      />
    </div>
  );
};

export default HorizontalBarChart;
