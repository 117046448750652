import React from 'react';
import { Link } from 'react-router-dom';
import { GoGlobe, GoDatabase } from 'react-icons/go';
import { LuBrain } from 'react-icons/lu';
import { FaRegMap, FaArrowRight } from 'react-icons/fa6';
import './about.css';

const About = () => {
  const featureCards = [
    {
      icon: <GoGlobe color="#fff" size={48} />,
      title: 'Real-Time Tourism Insights',
      description:
        'Capture live data on tourist sentiments, destinations, and experiences across Malawi.',
    },
    {
      icon: <GoDatabase color="#fff" size={48} />,
      title: 'Advanced Data Analysis',
      description:
        'Transform raw data into actionable insights for tourism businesses and policymakers.',
    },
    {
      icon: <LuBrain color="#fff" size={48} />,
      title: 'AI-Powered Predictions',
      description:
        'Leverage AI and NLP to forecast tourism trends and visitor preferences.',
    },
    {
      icon: <FaRegMap color="#fff" size={48} />,
      title: 'Geospatial Understanding',
      description:
        'Utilize Geographic Information Systems to map and analyze tourism patterns.',
    },
  ];

  const scrollToAbout = () => {
    document
      .querySelector('.about-section')
      .scrollIntoView({ behavior: 'smooth' });
  };

  const partners = [
    {
      image: require('./../../assets/visitmalawilogo.png'),
      url: 'https://visitmalawi.mw',
      alt: 'Visit Malawi',
    },
    {
      image: require('./../../assets/Coat_of_arms_of_Malawi.svg.png'),
      url: 'https://www.malawi.gov.mw',
      alt: 'Government of Malawi',
    },
    {
      image: require('./../../assets/sdgailab_logo.png'),
      url: 'https://sdgailab.org',
      alt: 'SDG AI Lab',
    },
    {
      image: require('./../../assets/undp_logo.png'),
      url: 'https://www.undp.org/malawi',
      alt: 'UNDP Malawi',
    },
  ];

  return (
    <div className="mlondola-about-page">
      <div className="hero-container">
        <div className="hero-overlay">
          <div className="hero-content">
            <h1>Welcome To Mlondola!</h1>
            <p>
              Enhancing Malawi's Tourism Sector Through Real-Time Data Insights
            </p>
            <div className="hero-buttons">
              <button onClick={scrollToAbout} className="hero-button about-btn">
                About Mlondola
              </button>
              <Link to="/home" className="hero-button explore-btn">
                Explore Mlondola
                <FaArrowRight size={20} className="button-icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <section className="technologies-section">
          <h2>Key Technologies</h2>
          <div className="technologies-grid">
            {featureCards.map((card, index) => (
              <div key={index} className="technology-card">
                <div className="technology-icon">{card.icon}</div>
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="about-section">
          <h2>About Mlondola</h2>
          <div className="about-content">
            <p>
              Mlondola is a joint initiative between UNDP Malawi Accelerator
              Lab, ICPSD SDG AI Lab and local partners, designed to enhance the
              tourism sector through real-time data insights in Malawi.
              Traditional reports, like the annual International Visitors' Exit
              Survey, leave a gap in timely information. To bridge this,
              Mlondola focuses on data analysis and interactive visualizations
              to provide actionable insights. <br/> <br/>
             
              The platform gathers real-time data from various sources to
              capture tourist sentiments, popular destinations, preferred
              accommodations, and activities. By leveraging advanced
              technologies such as Artificial Intelligence (AI), Natural
              Language Processing (NLP), and Geographic Information Systems
              (GIS), tourism businesses in Malawi can better understand visitor
              preferences, improve services, and boost customer satisfaction.<br/> <br/>

              Predictive analytics further forecast future trends based on
              historical data, helping stakeholders anticipate demand and make
              informed decisions. This innovative, open-source approach supports
              Malawi's tourism growth and sets a model for smarter, more
              responsive tourism management worldwide.
            </p>
          </div>
          <Link to="/home" className="hero-button explore-btn">
            Explore Mlondola
            <FaArrowRight size={20} className="button-icon" />
          </Link>
        </section>

        <section className="partners-section">
          <h2>Our Partners</h2>
          <div className="partners-logos">
            {partners.map((partner, index) => (
              <a
                key={index}
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
                className="partner-link"
                title={partner.alt}
              >
                <img
                  className="home-sponsors-image"
                  src={partner.image}
                  alt={partner.alt}
                />
              </a>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
