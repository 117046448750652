import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';
import DownloadAsPNGButton from '../download-png-btn/download-as-png-btn.js';
import { downloadDataAsExcel } from '../../utils/download-as-excel.js';
import { FaDownload, FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { formatFilters } from '../../utils/format-filters.js';
import './tree-map-chart.css'; 

const TreeMapChart = (props) => {
  const { id, data, hasLegend, height, filters, title } = props;
  const svgRef = useRef(null);

  const aspectRatio = 18 / 9;
  const chartHeight = height || 400;
  const chartWidth = chartHeight * aspectRatio;

  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');
  const [showAll, setShowAll] = useState(false);

  const colors = [
    '#AF8EF0', '#6FCF97', '#E6B65E', '#53b1fd', '#93a7ff',
    '#cf98fa', '#ff86de', '#ff79b3', '#ff7b81', '#ff8d4c',
    '#ffa600', '#AF8EF0'
  ];
  const colorsScale = d3.scaleOrdinal().range(colors);

  const updateChart = () => {
    if (!data || Object.keys(data).length === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const treeData = {
      name: "root",
      children: Object.entries(data).map(([key, value]) => ({
        name: key,
        value: value
      }))
    };

    const treemap = d3.treemap()
      .size([chartWidth, chartHeight])
      .padding(1);

    const root = d3.hierarchy(treeData)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value);

    treemap(root);

    const cell = svg
      .selectAll('g')
      .data(root.leaves())
      .join('g')
      .attr('transform', d => `translate(${d.x0},${d.y0})`);

    function mouseover(event, d) {
      d3.selectAll('.tree-cell').attr('opacity', 0.6);
      d3.select(this).attr('opacity', 1);
      setTooltipTitle(d.data.name);
      setTooltipValue(d.value);
      setTooltipVisible(true);
    }

    function mousemove(event) {
      setTooltipTop(event.offsetY + 'px');
      setTooltipLeft(event.offsetX + 'px');
    }

    function mouseleave() {
      d3.selectAll('.tree-cell').attr('opacity', 1);
      setTooltipVisible(false);
    }

    cell.append('rect')
      .attr('class', 'tree-cell')
      .attr('width', d => d.x1 - d.x0)
      .attr('height', d => d.y1 - d.y0)
      .attr('fill', d => colorsScale(d.data.name))
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave);
  };

  function computePercentage(n) {
    const vol = Object.values(data).reduce((a, b) => a + b, 0);
    return ((n / vol) * 100).toFixed(1);
  }

  useEffect(() => {
    updateChart();
  }, [data]);

  const handleDownloadExcel = () => {
    const chartData = Object.entries(data).map(([key, value]) => ({
      Category: key,
      Value: value,
      Percentage: `${computePercentage(value)}%`,
    }));
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    const filename = `distribution-of-types_${timestamp}.xlsx`;
    downloadDataAsExcel(filename, chartData);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const legendItems = Object.entries(data)
    .sort((a, b) => b[1] - a[1])
    .filter((d) => d[1] !== 0);

  const visibleItems = showAll ? legendItems : legendItems.slice(0, 9);

  if (!data || Object.keys(data).length === 0) {
    return null;
  }

  return (
    <div className="chart-wrapper">
      <div id={`chart-with-legend-${id}`}>
        <div className="chart-container" id={`chart-${id}`}>
          <svg
            ref={svgRef}
            width={chartWidth}
            height={chartHeight}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
          <ChartTooltip
            top={tooltipTop}
            left={tooltipLeft}
            title={tooltipTitle}
            visible={tooltipVisible}
            value={tooltipValue}
          />
        </div>
        {hasLegend && (
          <div className="treemap-legend">
            <div className="legend-grid">
              {visibleItems.map((d, i) => (
                <div key={i} className="legend-item">
                  <div
                    className="legend-color"
                    style={{ backgroundColor: colors[i % colors.length] }}
                  ></div>
                  <div className="legend-text">
                    {d[0]} ({`${computePercentage(d[1])}%`})
                  </div>
                </div>
              ))}
            </div>
            {legendItems.length > 9 && (
              <button 
                onClick={toggleShowAll} 
                className="read-more-btn"
              >
                {showAll ? (
                  <>
                    <span>Show Less</span>
                    <FaChevronUp />
                  </>
                ) : (
                  <>
                    <span>Show More</span>
                    <FaChevronDown />
                  </>
                )}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="download-btns">
        <button onClick={handleDownloadExcel} className="download-btn">
          <FaDownload color="white" />
        </button>
        <DownloadAsPNGButton
          chartId={`chart-with-legend-${id}`}
          fileName="treemap-chart.png"
          metadata={{
            title: title,
            filters: formatFilters(filters),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Mlondola AI for Tourism',
          }}
        />
      </div>
    </div>
  );
};

export default TreeMapChart;
