import React, { useState } from 'react';
import { Col, Row, Carousel } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';

import { links, SLIDES, COUNTRY_NAME } from '../../constants';
import './home.css';

const Link = (props) => {
  const { text, link, icon, onClick } = props;

  return (
    <NavLink
      className={({ isActive, isPending }) =>
        `home-navigation-link${isActive ? ' home-navigation-link-active' : ''}`
      }
      to={link}
      onClick={onClick}
    >
      <img
        className="home-navigation-link_icon"
        src={require(`./../../assets/icons/${icon}.svg`)}
        alt={text}
      />
      {text}
    </NavLink>
  );
};

const Slide = (props) => {
  const { text, title, image } = props;

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div
        style={{
          width: '100%',
          maxWidth: '100%',
          height: '600px',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          margin: '0 auto 20px auto',
          borderRadius: '15px',
          overflow: 'hidden',
        }}
      />
      <h2 className="home-slide-title">{title}</h2>
      <p className="home-slide-text">{text}</p>
    </div>
  );
};

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const partners = [
    {
      image: require('./../../assets/visitmalawilogo.png'),
      url: 'https://visitmalawi.mw',
      alt: 'Visit Malawi',
    },
    {
      image: require('./../../assets/Coat_of_arms_of_Malawi.svg.png'),
      url: 'https://www.malawi.gov.mw',
      alt: 'Government of Malawi',
    },
    {
      image: require('./../../assets/sdgailab_logo.png'),
      url: 'https://sdgailab.org',
      alt: 'SDG AI Lab',
    },
    {
      image: require('./../../assets/undp_logo.png'),
      url: 'https://www.undp.org/malawi',
      alt: 'UNDP Malawi',
    },
  ];

  const handleLinkClick = (link, event) => {
    event.preventDefault();
    navigate(link);
  };

  return (
    <>
      <Col className="home-container">
        <Row
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
         
          className="column"
          justify={{ md: 'center', lg: 'space-around'}}
          align="middle"
        >
          <h1 className="home-title">
            Let's explore
            <div className="home-title-accent">{COUNTRY_NAME}</div>
          </h1>
          <nav className="home-navigation">
            {links.map((link) => {
              return (
                <Link
                  key={link.text}
                  text={link.text}
                  link={link.link}
                  icon={link.icon}
                  onClick={(event) => handleLinkClick(link.link, event)}
                />
              );
            })}
          </nav>
        </Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="home-carousel"
        >
          <Carousel dots={false} arrows={true} autoplay>
            {SLIDES.map((slide, i) => {
              return (
                <Slide
                  key={`${i}-slide`}
                  text={slide.text}
                  title={slide.title}
                  image={slide.image}
                />
              );
            })}
          </Carousel>
        </Col>
        <div className="home-sponsors">
          {partners.map((partner, index) => 
            <a
              key={index}
              href={partner.url}
              target="_blank"
              rel="noopener noreferrer"
              className="partner-link"
              title={partner.alt}
            >
              <img
              className="home-sponsors-image"
              src={partner.image}
              alt={partner.alt}
              />
            </a>
          )}
        </div>
      </Col>
    </>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
